.MainContent{
    margin-top: 6em;
    display: grid;
    grid-template-columns: 10% 80% 10%;
    line-height: 1.2em;    
    font-size: 0.7em;

}
.MainContent h4, .MainContent p{
    margin-right: 6px;
}

.MainContent p{
    font-weight: 100;
    font-size: 0.9em;
    line-height: normal;
}

.MainContent .WeAre{
    margin-bottom: 2em;
    grid-column-start: 2;
    grid-column-end: 3;
    margin-bottom: 20px;
}
.MainContent h4{
    min-height: 20px;
    line-height: 0.9em;
    font-weight: 100;
    padding-bottom: 10px;
    font-size: 2em;
    border-bottom: rgb(86, 187, 128) solid 1px ;
}
.MainContent .services{
    grid-column-start: 2;
    grid-column-end: 3;
    display: grid;
    grid-template-columns: 49% 49%;
    column-gap: 2%;

}

.MainContent .services .services_mainText{
    grid-column-start: 1;
    grid-column-end: 3;
}

.MainContent .services .services_point1{
    border-right: 1px solid white ;
}

.MainContent .more_info{
    grid-column-start: 2;
    grid-column-end: 3;
    display: grid;
}


.MainContent .more_info h5{
    margin-top: 0;
    margin-bottom: 5px;
    border-bottom:1px solid white;
    display: inline-block;
}

.MainContent a{
    color: white;
}

.MainContent .competition{
    width: 100vw;
}

/*==========  Mobile First   ==========*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
    .MainContent h4{
        font-size: 1.2em;
    }
    .MainContent p{
        font-size: 0.7em;
    }
    
}    

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
    .MainContent{
        margin-top: 3em;  
    }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1300px) {
    
    .MainContent{
        justify-items: center;
        line-height: 62px;   
    }
    .MainContent h4 {
        font-size: 50px;
    }
    .MainContent p{
        font-size: 20px;
    }
    .MainContent .WeAre, .MainContent .services{
        max-width: 1040px;
    }

}
